import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import Layout from './layouts/Layout'
import PageRoutes from './routes/PageRoutes'

// eslint-disable-next-line import/no-webpack-loader-syntax
const theme = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!./styles/extraction.scss')

ReactGA.initialize('UA-114499308-3')
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <PageRoutes />
      </Layout>
    </ThemeProvider>
  )
}

export default App
