import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const Header = styled.header`
  height: calc(1.8rem + 1.5vw + 2 * ${({ theme }) => theme.spacers['2']});
  margin-bottom: ${({ theme }) => theme.spacers['5']};
  h1 {
    font-weight: 700;
    letter-spacing: 0.2rem;
    line-height: calc(1.8rem + 1.5vw);
    a {
      color: ${({ theme }) => theme.dark};
      text-decoration: none;
    }
  }
`

const Layout: FC = ({ children }) => {
  return (
    <Container>
      <Header>
        <h1 className="text-center text-uppercase border-bottom py-2">
          <NavLink to="/">Wordle Generator</NavLink>
        </h1>
      </Header>
      {children}
    </Container>
  )
}

export default Layout
