import React, { FC, Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import FallbackView from '../pages/FallBackView'

const PageRoutes: FC = () => {
  const GeneratePage = lazy(() => import('../pages/GeneratePage'))
  const RandomPage = lazy(() => import('../pages/RandomPage'))
  const PlayPage = lazy(() => import('../pages/PlayPage'))
  const NotFoundPage = lazy(() => import('../pages/NotFoundPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route path="/" element={<GeneratePage />} />
        <Route path="/random" element={<RandomPage />} />
        <Route path="/:gameId" element={<PlayPage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default PageRoutes
